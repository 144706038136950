var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Admin: CMS Updated" }
      }),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "one-half" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("CMS updated")
                  ]),
                  _c("gov-body", [_vm._v("The CMS content has been updated.")]),
                  _c(
                    "gov-button",
                    { attrs: { to: { name: "admin-index-cms" } } },
                    [_vm._v("Back to CMS")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }